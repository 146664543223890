import React from 'react';

export default function group2() {
  return (
    <div>
      <img
        className={'block mx-auto w-full'}
        src='img/Group_3 (1).png'
        alt=''
      />
    </div>
  );
}
